import React from "react"
import { Link, graphql } from "gatsby"
import { jsx, css } from "@emotion/core"

import SEO from "../components/seo"
import Header from "../components/header"

class Archive extends React.Component {
  render() {
    const { data } = this.props
    const posts = data.allMdx.edges
    return (
      <div
        css={css`
          color: white;
          width: 1200px;
          margin: 0 auto;
          margin-top: 150px;
          font-family: "Ramabhadra";
          font-size: 15px;
          @media (max-width: 1380px) {
            width: 800px;
          }
          @media (max-width: 950px) {
            width: 100%;
            padding-left: 25px;
            padding-right: 25px;
            box-sizing: border-box;
          }
        `}
      >
        <SEO
          title="Archive"
          description="This is the goldmine. A list of all that this website has to offer. It's a must-read for anyone who is remotely enthusiastic about... well... anything!"
        />
        <Header />
        <h2>Archive</h2>
        <ul>
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            const type = node.frontmatter.type
            const link = node.fields.slug
            const uniqueId = title + node.frontmatter.date
            return (
              <li key={uniqueId}>
                <Link to={link}>{title}</Link>
              </li>
            )
          })}
        </ul>
        <Link to="/">Go back to the homepage</Link>
      </div>
    )
  }
}

export const archiveQuery = graphql`
  query {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt(pruneLength: 280)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            type
          }
        }
      }
    }
  }
`

export default Archive
